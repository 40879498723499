:root {
  --adm-color-text: #333333;
  --adm-color-background: #ffffff;
  --adm-color-primary: #1677ff;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-text-secondary: #666666;
  --adm-color-fill: #f5f5f5;
  --adm-border-color: #eeeeee;
}

:root:root[data-prefers-color-scheme='dark'] {
  --adm-color-text: #ffffff;
  --adm-color-background: #1a202c;
  --adm-color-primary: #4299e1;
  --adm-color-success: #48bb78;
  --adm-color-warning: #ecc94b;
  --adm-color-danger: #f56565;
  --adm-color-text-secondary: #a0aec0;
  --adm-color-fill: #2d3748;
  --adm-border-color: #4a5568;
}
