/* Dark mode styles for Ant Design Mobile components */
.dark {
  /* General colors */
  --adm-color-text: #ffffff;
  --adm-color-text-secondary: #a0aec0;
  --adm-color-background: #1a202c;
  --adm-color-box: #2d3748;
  --adm-color-primary: #4299e1;
  --adm-color-success: #48bb78;
  --adm-color-warning: #ecc94b;
  --adm-color-danger: #f56565;
  
  /* Border colors */
  --adm-border-color: #4a5568;
  
  /* Component specific colors */
  --adm-color-background-elevated: #2d3748;
  --adm-color-fill-content: #2d3748;
  --adm-color-fill-header: #1a202c;
  --adm-color-box-background: #2d3748;
  
  /* NavBar */
  --adm-nav-bar-background-color: #1a202c;
  --adm-nav-bar-text-color: #ffffff;
  --adm-nav-bar-border-color: #4a5568;
  
  /* Collapse */
  --adm-collapse-header-background-color: #2d3748;
  --adm-collapse-content-background-color: #1a202c;
  
  /* Card */
  --adm-card-background: #2d3748;
  
  /* List */
  --adm-list-item-background: #2d3748;
  --adm-list-item-border-color: #4a5568;
  --adm-list-item-active-background: #4a5568;
  
  /* Form */
  --adm-form-background: #2d3748;
  --adm-form-border-color: #4a5568;
  
  /* Button */
  --adm-button-background-color: #2d3748;
  --adm-button-border-color: #4a5568;
  
  /* Tag */
  --adm-tag-background: #2d3748;
  
  /* Switch */
  --adm-switch-background: #4a5568;
  --adm-switch-checked-background: #4299e1;
  
  /* Pull to refresh */
  --adm-pull-to-refresh-background: #1a202c;
}

/* Additional dark mode styles for custom components */
.dark body {
  background-color: #1a202c;
  color: #ffffff;
}

/* Ant Design Mobile overrides for both light and dark mode */
:root {
  --adm-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif;
  
  --adm-border-radius: 8px;
}
