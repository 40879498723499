@tailwind base;
@tailwind components;
@tailwind utilities;

.adm-list-item-content-main {
  min-width: 0px;
}

body{
  margin-bottom: 36px;
}